import '../../styles/pages/spring-summer-2022/pillar-of-hope.scss';

import React from "react";
import Layout from '../../components/layout';
import { Helmet } from "react-helmet"
import ScrollAnimation from 'react-animate-on-scroll';
import MoreStories from "../../components/spring-summer-2022-stories/more-stories"
import Link from "gatsby-plugin-transition-link/AniLink";
// import { Parallax, Background } from 'react-parallax';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

import titleGraphic from "../../images/spring-summer-2022/pillar-of-hope/title-graphic.svg"

import socialBanner from "../../images/spring-summer-2022/social-banners/pillar-of-hope.jpg";


export default class Index extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    var pageTitle = 'A Pillar of Hope';
    var pageDesc = 'Military-connected students accomplish their personal and professional goals thanks to academic and emotional support from the Veterans Resource Center.';
    var slug = 'pillar-of-hope';

    return (
      <Layout headerStyle="white" locationInfo={this.props.location} issue="spring-summer-2022">
        <Helmet>
          <title>{pageTitle}</title>
          <body className={slug} />

          <meta name="description" content={pageDesc} />

          {/* Schema.org markup for Google+ */}
          <meta itemprop="name" content={pageTitle} />
          <meta itemprop="description" content={pageDesc} />
          <meta itemprop="image" content={socialBanner} />

          {/* Twitter Card data */}
          <meta name="twitter:title" content={pageTitle} />
          <meta name="twitter:description" content={pageDesc} />
          <meta name="twitter:image:src" content={socialBanner} />

          {/* Open Graph data */}
          <meta property="og:title" content={pageTitle} />
          <meta property="og:image" content={socialBanner} />
          <meta property="og:image:alt" content={pageTitle} />
          <meta property="og:description" content={pageDesc} />
        </Helmet>
        <div className="hero story animated fadeIn">

          <div class="hero-left">

            <div className="text">
              <h1 className="animated fadeInDown delay-1s"><img src={titleGraphic} alt={pageTitle} /></h1>
              <p className="sub-heading animated fadeInDown delay-1s">Military-connected students accomplish their personal and professional goals thanks to academic and emotional support from the Veterans Resource Center.</p>
              <span className="animated fadeInDown delay-1s byline">By Kendra Morales, Photos by Matt Gush</span>
            </div>

          </div>
          <div className='hero-right' />
          
        </div>
        <article className="wrap small">

          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          <p><span className="intro-text">Student veteran</span> James-Anthony Burandt is learning how to be an advocate for fellow veterans through his work as a peer adviser, administrative assistant and legislative intern thanks to Cal State Fullerton’s <a href="http://www.fullerton.edu/veterans/" target="_blank" rel="noreferrer">Veterans Resource Center</a>.</p>

          <p>Burandt, a senior political science major, understands how military-connected students have overcome difficult challenges. Burandt experienced instability and food insecurity throughout his childhood in Buffalo, New York, and San Diego County. He moved over 20 times while growing up, and didn’t have family or friends he could turn to for academic or emotional support.</p>
          </ScrollAnimation>

          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          <p>“I fell into the wrong crowd multiple times,” Burandt says. “I got in trouble with the police, I got suspended from school and I was going to Narcotics Anonymous meetings as a middle school student.”</p>

          <p>Burandt, 24, is one of about 600 student veterans and 1,000 military-connected students who access academic resources and a community of peer and professional mentors through the center.</p>

          <p>“The VRC definitely gave me that sense of belonging,” Burandt says. “It just gives me a sense of hope, like I have my headlights on now. Instead of only being able to see two feet in front of me, I can now see years ahead of me, and I never thought that was possible.”</p>
          </ScrollAnimation>

          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          <h2>Navigating Campus Life</h2>
          <p>For veterans and military-connected students who don’t fit the profile of a traditional college student, the Veterans Resource Center is a crucial part of their academic journey and campus life.</p>

          <p>In 2020, 15% of student veterans fit the mold of a traditional 18- to 22-year-old college student, according to the Department of Veterans Affairs. Student veterans are older — ages 24 to 40 — and are more likely to be first-generation students, married and have at least one dependent. Being a student is also the primary profession and source of income for student veterans. </p>
          </ScrollAnimation>

          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          <p>Meanwhile, military-connected students may experience stress from such life changes as frequent moving and losing their social networks.</p>

          <p>Burandt decided Cal State Fullerton was the right university for him after learning more about the VRC’s programs. Cal State Fullerton is one of 49 institutions in the nation that has a <a href="http://www.fullerton.edu/veterans/pave/index.php" target="_blank" ref="noreferrer">Peer Advisors for Veteran Education</a> (PAVE) program, and was the first of three California State Universities to join the network.</p>
          </ScrollAnimation>

          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          <p>“Conversations between advisers and students range from, ‘Do you know where I can park?’ to ‘I'm struggling right now. It's midterms and I'm stressed. I have anxiety. I have depression,’” says <a href="http://www.fullerton.edu/veterans/about/meetthestaff.php" target="_blank" ref="noreferrer">Alex Ortega</a>, the VRC’s access and engagement coordinator. “It’s about having an ally in their corner so that they can push through, get their degrees and better their lives.”</p>

          <p>As a peer adviser, Burandt was guiding student veterans toward services or support.</p>
          </ScrollAnimation>

          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          <p>“In a time of crisis, it is crucial to provide mental health first aid, which is what we are trained to do, to make sure a student veteran doesn’t become at risk of suicide,” Burandt says.</p>
          
          <p>Last fall, VRC director <a href="http://www.fullerton.edu/veterans/about/meetthestaff.php" target="_blank" ref="noreferrer">Cameron Cook</a> encouraged Burandt to participate in the <a href="http://calstatedcscholars.fullerton.edu/" target="_blank" ref="noreferrer">Cal State DC Scholars</a> program, which is how Burandt earned a fellowship representing the needs of student veterans and military-connected students in Washington, D.C.</p>
          </ScrollAnimation>

          <ScrollAnimation animateOnce={true} animateIn="fadeIn">

          <BrowserView>
          <aside className="related-stories align-outside">
            <h2>Veterans Resource Center Programs</h2>
            <ul>
              <li><a href="http://www.fullerton.edu/veterans/vetforward/index.php" target="_blank" ref="noreferrer">VetForward</a> prepares students transitioning into the professional workforce. The program offers virtual training on professional networking, resume and cover letter development, and job interview strategies.</li>
              <li><a href="http://www.fullerton.edu/veterans/future-students/" target="_blank" ref="noreferrer">Veteran Ambassador Program</a> offers group and individual CSU Apply and transfer workshops, virtual one-on-ones, community college campus virtual visits, and counseling opportunities with veteran peer ambassadors.</li>
              <li><a href="http://www.fullerton.edu/veterans/pave/index.php" target="_blank" ref="noreferrer">Peer Advisors for Veteran Education</a> (PAVE) connects incoming student veterans with current student veterans trained as peer advisers. Peer advisers help incoming students address challenges, and assist with finding resources on and off campus.</li>
              <li><a href="http://www.fullerton.edu/veterans/women/index.php" target="_blank" ref="noreferrer">Women for Women programs</a> offer opportunities for women veterans and military-connected students to meet and share their stories in an inclusive and supportive environment. Events include weekly discussion groups, monthly brunches and the Women Veterans in Higher Education Conference.</li>
              <li><a href="http://www.fullerton.edu/veterans/tww/index.php" target="_blank" ref="noreferrer">Titan Veteran Family</a> is an outreach program that provides veterans and military-connected students with individual support, activities and interactive workshops on improving well-being.</li>
            </ul>
          </aside>
          </BrowserView>

          <h2>Representation in Congress</h2>
          <p>As one of five students in the country to earn a prestigious 2022 <a href="https://www.vfw.org/community/student-veterans-of-america/student-fellowship" target="_blank" ref="noreferrer">Veterans of Foreign Wars and Student Veterans of America Legislative Fellowship</a>, Burandt is lobbying for student veterans and military-connected students at a national level. </p>

          <p>Burandt looks forward to working on policies that improve student veterans’ access to quality resources and health care. Burandt says it is empowering to meet not only advocates but also community leaders who are working to increase awareness of veterans’ issues. </p>

          <p>“The idea is to train medical professionals on common military terms and make sure the veteran identity is included at all facilities, not just Department of Veterans Affairs facilities,” Burandt says.</p>

          <p>Burandt’s undergraduate research focuses on veterans who turn to health care systems outside of the Veterans Health Administration because it has extremely long wait times. However, non-VHA health care providers can sometimes misdiagnose veterans or ignore their military experiences, Burandt says.</p>

          <p>“I want members of Congress to know that being a veteran is an identity and culture, not only a past occupation,” Burandt says. “I believe that all veterans can be future leaders in their own way.”</p>

          <p>Burandt plans to pursue a Master of Public Administration degree after earning his bachelor’s degree. <span className='end-mark' /></p>
          </ScrollAnimation>
          
          <MobileView>
          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          <aside className="related-stories align-outside">
            <h2>Veterans Resource Center Programs</h2>
            <ul>
              <li><a href="http://www.fullerton.edu/veterans/vetforward/index.php" target="_blank" ref="noreferrer">VetForward</a> prepares students transitioning into the professional workforce. The program offers virtual training on professional networking, resume and cover letter development, and job interview strategies.</li>
              <li><a href="http://www.fullerton.edu/veterans/future-students/" target="_blank" ref="noreferrer">Veteran Ambassador Program</a> offers group and individual CSU Apply and transfer workshops, virtual one-on-ones, community college campus virtual visits, and counseling opportunities with veteran peer ambassadors.</li>
              <li><a href="http://www.fullerton.edu/veterans/pave/index.php" target="_blank" ref="noreferrer">Peer Advisors for Veteran Education</a> (PAVE) connects incoming student veterans with current student veterans trained as peer advisers. Peer advisers help incoming students address challenges, and assist with finding resources on and off campus.</li>
              <li><a href="http://www.fullerton.edu/veterans/women/index.php" target="_blank" ref="noreferrer">Women for Women programs</a> offer opportunities for women veterans and military-connected students to meet and share their stories in an inclusive and supportive environment. Events include weekly discussion groups, monthly brunches and the Women Veterans in Higher Education Conference.</li>
              <li><a href="http://www.fullerton.edu/veterans/tww/index.php" target="_blank" ref="noreferrer">Titan Veteran Family</a> is an outreach program that provides veterans and military-connected students with individual support, activities and interactive workshops on improving well-being.</li>
            </ul>
          </aside>
          </ScrollAnimation>
          </MobileView>

          <p className="learn-more">Support the Veterans Resource Center<br /><a href="https://campaign.fullerton.edu/students" target="_blank" rel="noreferrer">campaign.fullerton.edu/students</a></p>

        </article>
        <MoreStories slug={slug} />
      </Layout>
    );
  }
} 